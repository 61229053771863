import React from 'react';

import Typography from '@material-ui/core/Typography';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Gallery = () => {
  const video1 =
    '<iframe src="https://www.youtube.com/embed/videoseries?list=PL_6A3vRFqYJbNY6KzkuW1LZP1_FDLcPwn&amp;ecver=2" width="640" height="360" frameborder="0" allow="autoplay; encrypted-media" style="border-width:0; display: block;margin: 0 auto;max-height: 100%; max-width: 100%; padding-bottom: 1rem;" allowfullscreen></iframe>';
  const video1frame = { __html: video1 };
  const video2 =
    '<iframe src="https://www.youtube.com/embed/videoseries?list=PL_6A3vRFqYJYXQ0iBnS4CDLX6g0-pxKEn&amp;ecver=2" width="640" height="360" frameborder="0" allow="autoplay; encrypted-media" style="border-width:0; display: block;margin: 0 auto;max-height: 100%; max-width: 100%;padding-bottom: 1rem;" allowfullscreen></iframe>';
  const video2frame = { __html: video2 };
  const lunarnewyear2019 = {
    __html:
      '<a data-flickr-embed="true"  href="https://www.flickr.com/photos/142314835@N02/albums/72157676212056417" title="2019年2月 春节联欢会"><img src="https://farm5.staticflickr.com/4912/32044364387_ce93f24fb7_z.jpg" alt="2019年2月 春节联欢会" ></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const yunli2 = {
    __html:
      '<a data-flickr-embed="true"  href="https://www.flickr.com/photos/142314835@N02/albums/72157699833576611" title="“芸之声”庆功会"><img src="https://farm8.staticflickr.com/7904/39637273293_1a60c2315b_z.jpg" alt="“芸之声”庆功会"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const yunliconcert = {
    __html:
      '<a data-flickr-embed="true"  href="https://www.flickr.com/photos/142314835@N02/albums/72157673180838498" title="2018年9月“芸之声”李芸独唱音乐会及花絮"><img src="https://farm2.staticflickr.com/1867/44542417471_1ab5803a16_z.jpg" alt="2018年9月“芸之声”李芸独唱音乐会及花絮"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const birthday = {
    __html:
      '<a data-flickr-embed="true" href="https://www.flickr.com/photos/142314835@N02/albums/72157712902020947" title="2018年5月在中心过青年节暨祝青青和新新生日快乐！"><img src="https://live.staticflickr.com/65535/49464597911_716a8c5db0_z.jpg" alt="2018年5月在中心过青年节暨祝青青和新新生日快乐！"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const singing = {
    __html:
      '<a data-flickr-embed="true" href="https://www.flickr.com/photos/142314835@N02/albums/72157712902048177" title="2018年3月东西方偶像歌唱大赛照片"><img src="https://live.staticflickr.com/65535/49464608071_4e76a308d6_z.jpg" alt="2018年3月东西方偶像歌唱大赛照片"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const indep = {
    __html:
      '<a data-flickr-embed="true" href="https://www.flickr.com/photos/142314835@N02/albums/72157712902077367" title="2017年国庆节联欢"><img src="https://live.staticflickr.com/65535/49464842332_c4c445c83a_z.jpg" alt="2017年国庆节联欢"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const poem = {
    __html:
      '<a data-flickr-embed="true" href="https://www.flickr.com/photos/142314835@N02/albums/72157712903502963" title="文化中心2016年春节联欢暨舒羽诗歌研讨会"><img src="https://live.staticflickr.com/65535/49464136453_3f0528f923_z.jpg" alt="文化中心2016年春节联欢暨舒羽诗歌研讨会"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  const karate = {
    __html:
      '<a data-flickr-embed="true" href="https://www.flickr.com/photos/142314835@N02/albums/72157712903522343" title="武术柔道班开业活动"><img src="https://live.staticflickr.com/65535/49464634551_b49d144bbc_z.jpg" alt="武术柔道班开业活动"></a><script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>',
  };
  return (
    <Layout>
      <SEO title="画廊 Gallery" />
      <Typography variant="h4" gutterBottom>
        Video Gallery
      </Typography>
      <div dangerouslySetInnerHTML={video1frame} />
      <div dangerouslySetInnerHTML={video2frame} />
      <Typography variant="h4" gutterBottom>
        Photo Gallery
      </Typography>
      <Typography gutterBottom>2019年2月 春节联欢会</Typography>
      {/* lunar new year festival */}
      <div dangerouslySetInnerHTML={lunarnewyear2019} />
      <Typography gutterBottom>“芸之声”庆功会</Typography>
      {/* Yun Li concert celebration (??) */}
      <div dangerouslySetInnerHTML={yunli2} />
      <Typography gutterBottom>
        2018年9月“芸之声”李芸独唱音乐会及花絮
      </Typography>
      {/* Yun Li concert */}
      <div dangerouslySetInnerHTML={yunliconcert} />
      <Typography gutterBottom>
        2018年5月在中心过青年节暨祝青青和新新生日快乐！
      </Typography>
      {/* birthday */}
      <div dangerouslySetInnerHTML={birthday} />
      <Typography gutterBottom>2018年3月东西方偶像歌唱大赛照片</Typography>
      {/* singing competition */}
      <div dangerouslySetInnerHTML={singing} />
      <Typography gutterBottom>2017年国庆节联欢</Typography>
      {/* independence day */}
      <div dangerouslySetInnerHTML={indep} />
      <Typography gutterBottom>
        文化中心2016年春节联欢暨舒羽诗歌研讨会
      </Typography>
      {/* Spring Festival Gala and Shu Yu Poetry Seminar */}
      <div dangerouslySetInnerHTML={poem} />
      <Typography gutterBottom>武术柔道班开业活动</Typography>
      {/* Karate photos */}
      <div dangerouslySetInnerHTML={karate} />
    </Layout>
  );
};

export default Gallery;
